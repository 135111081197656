<template>
  <div class="nav">首页 > 个人中心 > <span class="click" @click="$go($route.meta.backUrl)">{{ $route.meta.title }}</span> > 详情</div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
    .nav,span{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding-bottom: 11px;
      border-bottom: 1px solid #E0E0E0;
      margin-bottom: 40px;
    }
</style>
